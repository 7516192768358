.cols {
    display: flex;
    width: 50%;
    gap: 30px;
}
.col1 {
    width: 25%;
}

.col1 img {
    width: 100%;
}

.col2 {
    width: 100%;
    max-width: 70%;
}

.col2 p {
    margin: 8px auto;
}

.buttons { 
    width: 100%;
    margin: 0 50%;
    transform: translateX(-25%);
    display: flex;
    gap: 20px;
}

.btn_huge {
    position: relative;
    font-size: large;
    padding: 10px 30px;
    z-index: 10;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
    .cols {
        width: 60%;
    }
    .col1 {
        width: 20%;
    }
    .col2 {
        width: 80%;
        max-width: 100%;
    }
}