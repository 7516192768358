.gamefield {
    box-sizing: border-box;
    /* max-width: 60vw; */
    display: grid;
    gap: 10px;
    width: 70vw;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 15px;
    z-index: 10;
}

/* gfd4x4 */
.gfl1 {
    grid-template-columns: repeat(4, 1fr);
    max-width: 60vw;
}
/* gf6x4 */
.gfl2 {
    grid-template-columns: repeat(6, 1fr);
    max-width: 65vw;
}
/* gf8x8 */
.gfl3 {
    grid-template-columns: repeat(8, 2fr);
    max-width: 70vw;
}

.modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3vh 5vw;
    background-color: rgba(110, 110, 110, 0.8);
    color: white;
    border-radius: 20px;
    z-index: 11;
}

.modal__caption {
    color: #fadf12;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 20px;
}

.modal p {
    padding: 2.5px;
    margin: 0;
}

.modal span {
    color: #fadf12;
    font-weight: bolder;
}

.modal__buttons {
    display: flex;
    padding-top: 10px;
    gap: 10px;
}


.dragging {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
}

.tile {
    position: relative;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(226, 226, 226);
    padding-top: 59%;
    cursor: pointer;
}

.tile__paused {
    background-color: rgb(209, 209, 209);
    transition: 0.2s ease-in-out;
    cursor: auto;
}

.tile:hover {
    background-color: rgb(209, 209, 209);
    transition: 0.2s ease-in-out;
}

.tile img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    object-fit:cover;
}

.modal__activate {
    opacity: 0.5;
    transition: opacity 250ms ease-in-out;
}

.pair {
    background-color: red;
}

.img__container {
    width: 100%;
    height: 100%;
}

.game_start {
    margin: 20px;
    font-weight: 900;
    font-size: 0.8vw;
    padding: 1vw 2vw;
}

button {
    border-radius: 10px;
}

.center {
    align-self: center;
    text-align: center;
}

/* CSS for MacBook */
/* @media screen and (min-width: 1200px) {
    .gamefield {
        max-width: 60vw;
    }

    .tile {
        padding-top: 59%;
    }

    .game_start {
        font-size: 0.8vw;
        padding: 1vw 2vw;
    }
} */

/* CSS for Tablets */
/* @media screen and (min-width: 768px) and (max-width: 1199px) {
    .gamefield {
        max-width: 80vw;
    }

    .tile {
        padding-top: 75%;
    }

    .game_start {
        font-size: 1.5vw;
        padding: 2vw 3vw;
    }
} */

/* CSS for Mobile Phones */
/* @media screen and (max-width: 767px) {
    .gamefield {
        max-width: 90vw;
    }

    .tile {
        padding-top: 100%;
    }

    .game_start {
        font-size: 3vw;
        padding: 4vw 6vw;
    }
} */