* {
    box-sizing: border-box;
}

body {
    box-sizing: border-box;
    max-width: 100vw;
    height: 95vh;
    margin: 0;
    padding: 0;
    max-height: 95vh;
}

header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #f2f2f2;
    height: 7vh;
}

.header_buttons {
    display: flex;
    gap: 10px;
}

h1 {
    font-size: 24px;
    margin: 0;
}

button {
    padding: 10px 20px;
    background-color: #ffec5d;
    color: rgb(28, 28, 28);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 700;
}

.wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 93vh;
}

footer {
    position: relative;
    position: absolute;
    width: 100%;
    bottom: 1vh;
    text-align: center;
    color: gray;
    padding: 0;
    z-index: 0;
}

footer p {
    padding: 0;
    margin: 0;
}

.bold {
    font-weight: 700;
}

a {
    text-decoration: none;
    color: gray;
}

a:hover {
    text-decoration: underline;
}

@media screen and (min-width: 1200px) {}

@media screen and (min-width: 768px) and (max-width: 1199px) {
    .wrapper {
        height: 85vh;
    }
}